import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { observer } from 'mobx-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import HomeContainer from './containers/Home';
import SignInContainer from './containers/SingIn';
import SignUpContainers from './containers/SingUp';
import UsersContainer from './containers/Users';
import ThankYouContainer from './containers/ThankYou';
import ResetPasswordContainer from './containers/ResetPassword';
import UserProfileContainer from './containers/UserProfile';
import PlanContainer from './containers/Plan';
import ManualActivationContainer from './containers/ManualActivation';
import userStore from './stores/userStore';
import plansStore from './stores/plansStore';
import CancelLicenseContainer from './containers/CancelLicense';
import appStore from './stores/appStore';
import ProfileConfirm from './containers/ProfileConfirm';
import { loadReCaptcha } from 'recaptcha-v3-react-function-async'
import NotFound from './containers/404';

const App = () => {
  const { getUser } = userStore;
  const { getPlans } = plansStore;
  const { getAppInfo, setAppIsLoad } = appStore;

  useEffect(() => {
    getUser();
    getPlans();
    setTimeout(() => {
      setAppIsLoad();
    }, 1000); //TODO make a normal callback fired after requesting user data
    if (window.location.hostname.startsWith("dev.")) {
      getAppInfo();
    }
  });

  useEffect(() => {
    loadReCaptcha('6Lf3ur8qAAAAABvVwPMpKoZ71cyWf305NUa-BSq5')
      .then(() => {
        console.log('ReCaptcha loaded')
      })
      .catch((e) => {
        console.error('Error when load ReCaptcha', e)
      })
  }, [])

  return (
    <Router>
      <Header />
      <main>
        <Switch>
          <Route exact path="/">
            <HomeContainer />
          </Route>
          <Route exact path="/sign-in">
            <SignInContainer />
          </Route>
          <Route exact path="/sign-up">
            <SignUpContainers />
          </Route>
          <Route exact path="/profile-confirm">
            <ProfileConfirm />
          </Route>
          <Route path="/users">
            <UsersContainer />
          </Route>
          <Route exact path="/reset-password">
            <ResetPasswordContainer />
          </Route>
          <Route exact path="/profile">
            <UserProfileContainer />
          </Route>
          <Route path="/plans/:id">
            <PlanContainer />
          </Route>
          <Route path="/thankyou">
            <ThankYouContainer />
          </Route>
          <Route exact path="/cancel">
            <CancelLicenseContainer />
          </Route>
          <Route exact path="/activation/manual">
            <ManualActivationContainer />
          </Route>
          <Route path='*' exact>
            <NotFound />
          </Route>
        </Switch>
      </main>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false} />
    </Router>
  )
}

export default observer(App);
