import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { eq, get } from 'lodash/fp';
import HttpStatus from 'http-status-codes';
import plansStore from '../../stores/plansStore';
import './CheckoutForm.css';
import { Select } from 'antd';
import * as ga from '../../seo/ga'
import { HandySvg } from 'handy-svg';

const { Option } = Select;

const CheckoutForm = ({ isFree, form, plan }) => {


  const { priceWithCoupon } = plansStore;
  useEffect(() => {
    return () => {
      form.reset()
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  const handlePay = async (event) => {
    event.preventDefault();

    const values = form.values();

    try {
      const params = {
        seats: Number(values.seats),
        commitment: values.commitment
      };

      const { data } = await plansStore.payPlan(plan.id, params);
      const { payment_url } = data;
      ga.clickEvent(`${plan.id}-click-to-subscribe`)
      window.location.href = payment_url;

    } catch (e) {

      if (eq(HttpStatus.FORBIDDEN, get('response.status')(e))) {

      }
      if (eq(HttpStatus.BAD_REQUEST, get('response.status')(e))) {
        toast.error(get('response.data.message')(e));
      }
      if (eq(HttpStatus.UNAUTHORIZED, get('response.status')(e))) {
        window.location.href = '/sign-in';
      }
      console.log("error end", e)
    }
  };


  const onChangeSeats = (value) => {
    form.update({ "seats": value });
  }

  const onChangeCommitment = (value) => {
    form.update({ "commitment": value });
  }

  const getPrice = () => {
    if (form.values().commitment === "yearly") {
      return (plan.price_yearly_usd || 0).toFixed(2);
    }
    return plan.price_usd.toFixed(2);
  }

  const getPriceFormatted = () => {
    const price = getPrice();
    if (form.values().commitment === "yearly") {
      return `${price} per year`;
    }
    return `${price} per month`;
  }

  const subtotal = () => {
    return getPrice() * form.values().seats;
  }

  const subtotalMY = () => {
    return plan.price_usd * 12 * form.values().seats;
  }

  const amount = () => {
    return priceWithCoupon || subtotal();
  }

  const getSavingsAmount = () => {
    if (form.values().commitment === "yearly") {
      const sa = (subtotalMY() - subtotal()) + (subtotal() - amount())
      if (sa >= subtotal()) {
        return subtotal();
      }
      return sa.toFixed(2);
    }
    return (subtotal() - amount()).toFixed(2);
  }

  const getTrialDays = () => {
    if (plan.type === "VQPROBE") {
      return 14;
    }
    return 3;
  }

  const getCommitment = () => {
    return form.values().commitment;
  }

  const formatD = (d) => {
    if (!d) {
      return ""
    }

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const date = new Date(d);
    const month = monthNames[date.getMonth()];
    return (
      `${month} ${date.getDate()}, ${date.getFullYear()}`
    )
  }

  const cancelBefore = () => {
    let d = new Date();
    d.setDate(d.getDate() + getTrialDays());
    return formatD(d);
  }

  return (
    <>
      <form>
        <div className="CheckoutForm">

          <div className="CheckoutForm-information">
            <div className="CheckoutForm-information-text">You're trying</div>
            <div className="CheckoutForm-information-content">
              <div className="CheckoutForm-information-plan">
                {(plan.product_name === "VQAnalyzer" || plan.product_name === "VQ Analyzer") &&
                  <HandySvg width={44} height={44} src={"/static/vq_analyzer_icon.svg"} />
                }
                {(plan.product_name === "VQProbe" || plan.product_name === "VQ Probe") &&
                  <HandySvg width={44} height={44} src={"/static/vq-probe.svg"} />
                }

                <div className="CheckoutForm-information-plan__text">{plan.product_name}<br /> <span className="CheckoutForm-information-plan__text--bold">{plan.product_mode}</span></div>
                {!isFree &&
                  <div className="CheckoutForm-information-plan__price-text">
                    €{getPriceFormatted()}
                  </div>
                }
              </div>
              <div className="Checkout-form-information__pair-of-label-and-select">
                <label htmlFor="seats" className="Checkout-form-information__pair-of-label-and-select--label">Seats <span>*</span></label>
                <Select
                  id="seats"
                  showSearch
                  placeholder=""
                  optionFilterProp="children"
                  defaultValue="1"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={onChangeSeats}
                  className="Checkout-form-information__pair-of-label-and-select--select"
                >
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                </Select>
              </div>
              <div className="Checkout-form-information__pair-of-label-and-select">
                <label htmlFor="commitment" className="Checkout-form-information__pair-of-label-and-select--label">Commitment <span>*</span></label>
                <Select
                  id="commitment"
                  showSearch
                  placeholder=""
                  optionFilterProp="children"
                  defaultValue="monthly"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={onChangeCommitment}
                  className="Checkout-form-information__pair-of-label-and-select--select"
                >
                  <Option value="monthly">Monthly</Option>
                  <Option value="yearly">Yearly</Option>
                </Select>
              </div>

              <hr className="Checkout-form-information__line" />
              <div className="Checkout-form-information__money-info">
                <div className="Checkout-form-information__money-info--row">
                  <span className="Checkout-form-information__money-info--heading">Subtotal</span><span className="Checkout-form-information__money-info--amount">€{subtotal()}</span>
                </div>
                <div className="Checkout-form-information__money-info--row">
                  <span className="Checkout-form-information__money-info--heading blue">Your Saving</span><span className="Checkout-form-information__money-info--amount blue">€{getSavingsAmount()}</span>
                </div>
                <div className="Checkout-form-information__money-info--row">
                  <span className="Checkout-form-information__money-info--heading">Due now</span><span className="Checkout-form-information__money-info--amount">€{amount()}</span>
                </div>
                <p className="CheckoutForm-payment-agreement-info">
                  By clicking "Agree and subscribe," you agree:
                  After your trial ends, you will be charged EUR {subtotal()} free {getTrialDays()} days and at the end of your one-year term,
                  your subscription will automatically renew {getCommitment()} until you cancel (price subject to change).
                  Cancel before your trial ends and you won’t be charged.
                  No annual commitment required after the first year.
                  Cancel anytime via <a href="mailto:info@vicuesoft.com" title="Contact us">Contact US</a>.
                  Cancel before {cancelBefore()} to get a full refund and avoid a fee.
                  You also agree to the <a href="https://vicuesoft.com/terms-of-use">Terms of Use</a> and
                  the <a href="https://vicuesoft.com/privacy-policy">Privacy Policy</a>.
                </p>

                <button className="CheckoutForm-continue-button" onClick={handlePay}>
                  Agree and subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default observer(CheckoutForm);
