import { observable, action, decorate, computed, flow } from 'mobx';
import api from '../api/http';
import { eq, get } from 'lodash/fp';
import HttpStatus from 'http-status-codes';
import { toast } from 'react-toastify';
import { reCaptchaExecute } from 'recaptcha-v3-react-function-async';

class UserStore {
  user = null;
  resetPasswordSent = false;
  resetPasswordEmailNotFound = false;
  authFailed = false;
  changePasswordErrorMessage = null;
  isSignUp = false;

  get isSuperAdmin() {
    return this.user && this.user.role === 'superadmin'
  }

  singIn = (data) => flow(function* (self) {
    self.clearAuthState();

    try {
      const signInResp = yield api.signIn(data);
      const { token } = signInResp.data;
      if (token) {
        api.setTokenHeader(token);
        const userResp = yield api.getUser();
        self.user = userResp.data;
        return true
      }
    } catch (e) {
      if (eq(get('response.status')(e))) {
        toast.error(get('response.data')(e).message)
      }
      return false
    }
  })(this)

  singUp = (data) => flow(function* (self) {
    let ReCaptchaToken = yield reCaptchaExecute('6Lf3ur8qAAAAABvVwPMpKoZ71cyWf305NUa-BSq5', 'SignUp')
    data = {...data, recaptcha_token: ReCaptchaToken}

    try {
      yield api.signUp(data);
      self.isSignUp = true
    } catch (e) {
      if (eq(get('response.status')(e))) {
        toast.error(get('response.data')(e).message)
      }
    }
  })(this)

  signOut = () => {
    api.signOut();
    this.user = null
  }

  clearIsSignUp = () => {
    this.isSignUp = false
  }

  getUser = () => flow(function* (self) {
    const userResp = yield api.getUser();
    self.user = userResp.data;
  })(this)

  resetPassword = (data) => flow(function* (self) {
    self.resetPasswordEmailNotFound = false;

    try {
      yield api.resetPassword(data);
      self.resetPasswordSent = true;
    } catch (e) {
      if (eq(HttpStatus.NOT_FOUND, get('response.status')(e))) {
        self.resetPasswordEmailNotFound = true;
      }
    }

  })(this)

  changePassword = (data) => flow(function* (self) {
    try {
      yield api.changePassword(data);
      toast.success("Password changed successfully");
    } catch (e) {
      if (eq(HttpStatus.UNPROCESSABLE_ENTITY, get('response.status')(e))) {
        toast.error(get('response.status')(e))
      }
    }
  })(this)

  changeCompany = (data) => flow(function* (self) {
    const resp = yield api.changeCompany(data);
    toast.success("Company name changed successfully");
    self.user = resp.data;
  })(this)

  clearResetPasswordState = () => {
    this.resetPasswordSent = false;
    this.resetPasswordEmailNotFound = false;
  }

  clearAuthState = () => {
    this.authFailed = false;
  }

  deleteUser = () => flow(function* (self) {
    try {
      const resp = yield api.userDelete();
      self.user = null
      toast.success(resp.data.message);
    } catch (e) {
      toast.error(get('response.status')(e))
    }
  })(this)

  checkingPossibilityUserDelete = () => flow(function* () {
    try {
      let checkingPossibilityUserDeleteResp = yield api.checkingPossibilityUserDelete();
      return checkingPossibilityUserDeleteResp
    } catch (e) {
      toast.error(get('response.status')(e))
    }
  })(this)

  setToken = (token) => {
    api.setTokenHeader(token);
  }
}

decorate(UserStore, {
  user: observable,
  isSuperAdmin: computed,
  resetPasswordSent: observable,
  resetPasswordEmailNotFound: observable,
  changePasswordErrorMessage: observable,
  authFailed: observable,
  isSignUp: observable,
  singIn: action,
  singOut: action,
  getUser: action,
  resetPassword: action,
  clearResetPasswordState: action,
  clearAuthState: action,
  changePassword: action,
  changeCompany: action,
  setToken: action,
  checkingPossibilityUserDelete: action,
  deleteUser: action,
  clearIsSignUp: action
});

export default new UserStore();